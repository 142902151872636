import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { useCallback, useState } from "react";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { DropDownBox, DropDownBoxTypes } from "devextreme-react/drop-down-box";
import { TreeView, TreeViewTypes } from "devextreme-react/tree-view";
import InfoIcon from "../../assets/icons/ic_info_24px.svg";
import { Validator, RequiredRule, StringLengthRule } from "devextreme-react/validator";
import {
  gqlAddDevice,
  gqlDeleteDevice,
  gqlLocations,
  gqlModels,
  gqlQueryDevice,
  gqlQueryDeviceDependencies,
  gqlUpdateDevice,
} from "./DeviceQueries";
import { query, mutation } from "../../Services/GraphQL";
import { IDevice } from "./IDevice";
import { deleteEntityGeneric } from "../ConfirmDeleteModal/ConfirmDeleteModalPilet";
import { ILocation } from "./ILocation";
import EditButtonRow from "../../Components/EditButtonRow/EditButtonRow";
import { GenericOkButtonPiletName } from "../../Components/Modals/GenericOkButtonModal";
import { SelectBox } from "devextreme-react";
import { IModel } from "./IModel";
import { isNullOrUndefined, isNullOrWhitespace } from "../../util/nullUtilities";

const devicePropertiesComponent = (api: PiletApi) => {
  const emptyDevice = {
    id: "",
    name: "",
    externalId: "",
    model: { id: "", name: "", manufacturer: { name: "" }, deviceType: { name: "" } },
    deviceType: "",
    manufacturer: "",
    site: { id: "", name: "" },
    location: { id: "", name: "" },
    dependencies: [],
  };

  const [site, setSite] = useState<string>("");
  const [device, setDevice] = useState<IDevice>(emptyDevice);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [models, setModels] = useState<IModel[]>([]);
  const [filteredLocations, setFilteredLocations] = useState<ILocation[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isTreeBoxOpen, setIsTreeBoxOpen] = useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const treeViewRef = React.useRef(null);

  const formPaddingStyle: React.CSSProperties = { paddingBottom: "1rem" };

  React.useEffect(() => {
    setSite(api.getData("currentSite"));
    refreshLocations();

    api.on("store-data", async ({ name, value }) => {
      if (name === "selectedDevice") {
        await refreshModels();
        await refreshData(value?.id);
      }

      if (name === "currentSite") {
        setSite(value);
        await refreshLocations();
      }
    });
  }, []);

  React.useEffect(() => {}, [device]);

  const filterLocations = (locations: ILocation[], site: string) => {
    if (site === "*" || isNullOrWhitespace(site)) {
      setFilteredLocations(locations);
    } else {
      const filtered = locations.filter((x) => x.site.id === site);
      setFilteredLocations(filtered);
    }
  };

  const resetDevice = () => {
    setDevice(emptyDevice);
  };

  const refreshLocations = async () => {
    const result = await query<ILocation[]>(gqlLocations);
    setLocations(result);
    filterLocations(result, site);
  };

  const refreshModels = async () => {
    const result = await query<IModel[]>(gqlModels);
    setModels(result);
  };

  const refreshData = async (deviceId) => {
    setEditMode(false);
    if (!deviceId) {
      resetDevice();
      return;
    }

    const result = await query<IDevice[]>(gqlQueryDevice, {
      deviceIds: [deviceId],
    });
    if (result.length ?? 0 > 0) {
      setDevice(result[0]);
    } else {
      resetDevice();
    }
    setDeleteInProgress(false);
  };

  const handleValueChanged = async (e, field) => {
    if (field === "modelId") {
      var newDevice = { ...device };
      newDevice.model = { id: e, name: "", manufacturer: { name: "" }, deviceType: { name: "" } };
      setDevice(newDevice);
      return;
    }
    if (e.event) {
      var newDevice = { ...device };
      newDevice[field] = e.value;
      setDevice(newDevice);
    }
  };

  const deleteDevice = async () => {
    setDeleteInProgress(true);
    const result = await deleteEntityGeneric<IDevice>(
      api,
      device.id,
      "device",
      gqlQueryDeviceDependencies,
      gqlDeleteDevice
    );

    if (result === true) {
      resetDevice();
    }
    setDeleteInProgress(false);
  };

  const updateEntity = async (e) => {
    if (!e.validationGroup.validate().isValid) return;

    try {
      const deviceToSave = {
        name: device.name,
        externalId: device.externalId,
        modelId: device.model?.id,
        locationId: device.location.id,
      };

      if (device.id === "*") {
        if (deviceToSave.modelId == "") deviceToSave.modelId = null;
        const result = await mutation<IDevice>(gqlAddDevice, {
          input: deviceToSave,
        });

        if (result) {
          setDevice(result);
          api.emit("refresh", { name: "device", value: result.id });
          setEditMode(!editMode);
        } else {
          api.showNotification("Error adding device", { type: "error" });
        }

        return;
      }

      const result = await mutation<IDevice>(gqlUpdateDevice, {
        id: device.id,
        input: deviceToSave,
      });
      if (result) {
        setDevice(result);
        api.emit("refresh", { name: "device", value: result.id });
        setEditMode(!editMode);
      } else {
        api.showNotification("Error updating device", { type: "error" });
      }
    } catch (error) {
      console.error("Error refreshing data:", error);
    }
  };

  const treeViewItemSelectionChanged = useCallback((e: any) => {
    setDevice((d) => {
      return { ...d, location: { id: e.node.key, name: e.node.text } };
    });
    console.debug("New device location", e.node.key, e.node.text);
  }, []);

  const syncTreeViewSelection = useCallback((e: DropDownBoxTypes.ValueChangedEvent) => {
    setDevice((d) => {
      return { ...d, location: { id: e.value, name: "" } };
    });
    if (!treeViewRef.current) return;

    if (!e.value) {
      treeViewRef.current.instance.unselectAll();
    } else {
      treeViewRef.current.instance.selectItem(e.value);
    }
  }, []);

  const treeViewOnContentReady = useCallback(
    (e: TreeViewTypes.ContentReadyEvent) => {
      if (!isNullOrUndefined(device.location?.id)) {
        e.component.selectItem(device.location?.id);
      } else {
        e.component.unselectAll();
      }
    },
    [device]
  );

  const onTreeItemClick = useCallback(() => {
    setIsTreeBoxOpen(false);
  }, []);

  const treeViewRender = useCallback(
    () => (
      <TreeView
        dataSource={filteredLocations}
        ref={treeViewRef}
        dataStructure="plain"
        keyExpr="id"
        parentIdExpr="parentId"
        selectionMode="single"
        displayExpr="name"
        selectByClick={true}
        onContentReady={treeViewOnContentReady}
        onItemClick={onTreeItemClick}
        onItemSelectionChanged={treeViewItemSelectionChanged}
      />
    ),
    [treeViewRef, filteredLocations, treeViewOnContentReady, onTreeItemClick, treeViewItemSelectionChanged]
  );

  const onTreeBoxOpened = useCallback((e: DropDownBoxTypes.OptionChangedEvent) => {
    if (e.name === "opened") {
      setIsTreeBoxOpen(e.value);
    }
  }, []);

  const onAddClick = () => {
    if (site === "*") {
      api.showModal(GenericOkButtonPiletName, {
        title: "Choose a site",
        body: "To create a new device, choose a site first.",
        onClose: () => {},
      });
      return;
    }
    setEditMode(true);
    filterLocations(locations, site);
    setDevice({ ...emptyDevice, id: "*" });
  };

  if (device.id === "")
    return (
      <div>
        <div style={{ display: "flex" }}>
          {site !== "*" ? (
            <>
              <div style={{ flexGrow: 1 }}></div>
              <div style={{ padding: "0.75rem 1rem" }}>
                <Button width={120} text="Outlined" type="normal" stylingMode="outlined" onClick={onAddClick}>
                  <span className="dx-icon-add" style={{ fontSize: "18px" }}></span>
                  Add New
                </Button>
              </div>
            </>
          ) : (
            <div>To add a device select a site.</div>
          )}
        </div>
      </div>
    );

  return (
    <div>
      <form>
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }}></div>
          <EditButtonRow
            editMode={editMode}
            deleteInProgress={deleteInProgress}
            onCancel={() => {
              if (device.id == "*") {
                resetDevice();
              } else {
                refreshData(device.id);
              }

              setEditMode(false);
              setIsTreeBoxOpen(false);
            }}
            onEdit={() => {
              filterLocations(locations, device.site.id);
              setEditMode(true);
            }}
            onDelete={() => deleteDevice()}
            onSave={updateEntity}
            onAdd={onAddClick}
          />
        </div>
        <div style={{ padding: "0 1rem 1rem 1rem" }}>
          <div style={formPaddingStyle}>
            <TextBox
              readOnly={!editMode}
              label="Name"
              onValueChanged={(e) => handleValueChanged(e, "name")}
              labelMode="floating"
              stylingMode="outlined"
              value={device.name}
            >
              <Validator>
                <RequiredRule message="Name is required" />
                <StringLengthRule max={50} message="Max 50 characters" />
              </Validator>
            </TextBox>
          </div>
          <div style={formPaddingStyle}>
            <TextBox
              readOnly={!editMode}
              label="External ID"
              onValueChanged={(e) => handleValueChanged(e, "externalId")}
              labelMode="floating"
              stylingMode="outlined"
              value={device.externalId}
            >
              <Validator>
                <StringLengthRule max={50} message="Max 50 characters" />
              </Validator>
            </TextBox>
          </div>
          <div style={formPaddingStyle}>
            <SelectBox
              readOnly={!editMode}
              label="Model"
              onValueChange={(e) => {
                handleValueChanged(e, "modelId");
              }}
              labelMode="floating"
              stylingMode="outlined"
              value={device.model?.id}
              items={models}
              valueExpr="id"
              displayExpr="name"
              showClearButton={true}
            ></SelectBox>
          </div>
          <div style={formPaddingStyle}>
            <DropDownBox
              readOnly={!editMode}
              dataSource={filteredLocations}
              value={device.location?.id}
              label="Location"
              displayExpr="name"
              labelMode="floating"
              stylingMode="outlined"
              valueExpr="id"
              showClearButton={true}
              onValueChanged={syncTreeViewSelection}
              onOptionChanged={onTreeBoxOpened}
              contentRender={treeViewRender}
              opened={isTreeBoxOpen}
            >
              <Validator>
                <RequiredRule message="Location is required" />
              </Validator>
            </DropDownBox>
          </div>
        </div>
      </form>
    </div>
  );
};

export const DevicePropertiesPilet: Pilet = {
  name: "Device Properties Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerExtension<"device-properties">("device-properties", () => devicePropertiesComponent(api), {
      type: "device",
      sortOrder: 1,
      name: "Device Properties",
      icon: InfoIcon,
    });
  },
};
