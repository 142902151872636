import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { Link } from "react-router-dom";
import { useState } from "react";
import SiteIcon from "../../assets/icons/ic_locations_buildings_24px.svg";
import { gqlLibraryItems } from "./LibraryItemQuery";
import { query } from "../../Services/GraphQL";
import DataGrid, { Column, Item, Toolbar } from "devextreme-react/data-grid";
import Tabs from "devextreme-react/tabs";
import SoundIcon from "../../assets/icons/sound.jpg";
import { ILibraryItem } from "./ILibraryItem";

export const LibraryItemPilet: Pilet = {
  name: "Library Item Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerMenu(
      "LibraryItems",
      () => (
        <Link to="/libraryitems">
          <div>
            <div>
              <img src={SiteIcon}></img>
            </div>
            <div>Library Items</div>
          </div>
        </Link>
      ),
      { type: "general", sortOrder: 7, location: "/libraryitems" }
    );

    api.registerTile(
      "LibraryItems",
      () => {
        const smallTileStyle: React.CSSProperties = {
          backgroundImage: "url(" + SiteIcon + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#d8d8d8",
          backgroundSize: "cover",
          borderRadius: "0.5rem",
          verticalAlign: "middle",
          textAlign: "center",
          paddingTop: "40px",
        };
        return (
          <div className="tile">
            <div style={smallTileStyle}>
              <Link to="/libraryitems">Library items</Link>
              <br />
              Administration of library items.
            </div>
          </div>
        );
      },
      { initialColumns: 2, initialRows: 2 }
    );

    api.registerPage("/libraryitems", ({ children }) => {
      const [libraryItems, setLibraryItems] = useState<ILibraryItem[]>([]);

      const refreshData = async () => {
        const result = await query<ILibraryItem[]>(gqlLibraryItems);
        setLibraryItems(result);
      };

      React.useEffect(() => {
        if (api.getData("currentTenant") != null) refreshData();

        api.on("refresh", (data) => {
          if (data.name === "site") {
            refreshData();
          }

          if (data.name === "libraryItem") {
            refreshData();
          }
        });

        api.on("store-data", ({ name, value }) => {
          if (name === "currentSite") {
            console.log("DataChanged name:" + name + " value:" + value);
            refreshData();
            api.setData("selectedLibraryItem", "");
          }
        });
      }, []);

      const onRowSelected = (row) => {
        api.setData("selectedLibraryItem", row.selectedRowsData.length === 0 ? "" : row.currentSelectedRowKeys[0]);
      };

      const tabs = [
        {
          id: 0,
          text: "Library Items",
          icon: SiteIcon,
          content: "Library Item content",
        },
      ];

      const imageUrl = (url, contentType) => {
        if (contentType.startsWith("image/")) return url + "&width=25";

        return SoundIcon;
      };

      const renderIcon = (e) => {
        return (
          <>
            <span style={{ display: "inline-block" }}>
              <img style={{ width: "25px", height: "25px" }} src={imageUrl(e.data.url, e.data.contentType)} />
            </span>
          </>
        );
      };

      return (
        <>
          <div style={{ display: "flex", height: "100%", flexDirection: "column", borderRadius: "0.25rem" }}>
            <div style={{ display: "flex", flexGrow: 2, margin: " 0 1rem 1rem 1rem", backgroundColor: "#FFF" }}>
              <div className="list-items-container">
                <DataGrid
                  onSelectionChanged={onRowSelected}
                  selection={{ mode: "single" }}
                  dataSource={libraryItems}
                  keyExpr="id"
                  showBorders={true}
                  searchPanel={{ visible: true, width: 240, placeholder: "Search..." }}
                  onEditorPreparing={(e) => {
                    if (e.parentType === "searchPanel") {
                      e.editorOptions.stylingMode = "outlined";
                    }
                  }}
                >
                  <Toolbar>
                    <Item location="before">
                      <div style={{ fontSize: 24, opacity: 0.8 }}>Library Items</div>
                    </Item>
                    <Item name="searchPanel"></Item>
                  </Toolbar>
                  <Column name="Category" dataField="category" dataType="string" groupIndex={0} />
                  <Column name="Item" dataField="item" dataType="string" />
                  <Column name="Content Type" dataField="contentType" dataType="string" />
                  <Column caption="Icon" cellRender={renderIcon} />
                </DataGrid>
              </div>
              <div className="extension-list">
                <api.Extension name="extension-list" params={{ extensions: "libraryItem" }} />
              </div>
            </div>
          </div>
        </>
      );
    });
  },
};
