import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { Link } from "react-router-dom";
import OrganizationIcon from "../../assets/icons/ic_my_units_24px.svg";
import { useState } from "react";
import { gqlOrganization, gqlOrganizations } from "./OrganizationQuery";
import { query } from "../../Services/GraphQL";
import TreeView, { SearchEditorOptions } from "devextreme-react/tree-view";
import Tabs from "devextreme-react/tabs";
import { ISelectableOrganization } from "./IOrganization";
import LoadIndicator from "devextreme-react/load-indicator";

export const OrganizationPilet: Pilet = {
  name: "Organization Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerMenu(
      "Organizations",
      () => (
        <Link to="/organizations">
          <div>
            <div>
              <img src={OrganizationIcon}></img>
            </div>
            <div>Organizations</div>
          </div>
        </Link>
      ),
      { type: "general", sortOrder: 1, location: "/organizations" }
    );

    api.registerTile(
      "Organization",
      () => {
        const smallTileStyle: React.CSSProperties = {
          backgroundImage: "url(" + OrganizationIcon + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#d8d8d8",
          backgroundSize: "cover",
          borderRadius: "0.5rem",
          verticalAlign: "middle",
          textAlign: "center",
          paddingTop: "40px",
        };
        return (
          <div className="tile">
            <div style={smallTileStyle}>
              <Link to="/organizations">Organizations</Link>
              <br />
              Administration of organizations.
            </div>
          </div>
        );
      },
      { initialColumns: 2, initialRows: 2 }
    );

    const tabs = [
      {
        id: 0,
        text: "Organizations",
        icon: OrganizationIcon,
        content: "Organizations content",
      },
    ];

    api.registerPage("/organizations", ({ children }) => {
      const [organizations, setOrganizations] = useState<ISelectableOrganization[]>([]);
      const [isLoading, setIsLoading] = useState(true);

      const refreshData = async () => {
        setIsLoading(true);
        const result = await query<ISelectableOrganization[]>(gqlOrganizations);
        result.forEach((org) => {
          org.selected = false;
        });
        setOrganizations(result);
        setIsLoading(false);
      };

      const refreshSingleData = async (organizationId) => {
        const result = await query<ISelectableOrganization[]>(gqlOrganization, {
          organizationId: [organizationId],
        });
        var organization = { ...result[0], selected: true };

        setOrganizations((orgs) => {
          const updatedOrganizations = orgs.map((org) => {
            if (org.id === organizationId) {
              return organization;
            }
            return org;
          });

          return updatedOrganizations;
        });
      };

      const addSingleData = async (organizationId) => {
        const result = await query<ISelectableOrganization[]>(gqlOrganization, {
          organizationId: [organizationId],
        });
        var organization = { ...result[0], selected: true };

        setOrganizations((orgs) => {
          const updatedOrganizations = orgs.map((org) => {
            if (org.selected) {
              return { ...org, selected: false };
            }
            return org;
          });

          return [...updatedOrganizations, organization];
        });
      };

      const removeSingleData = async (organizationId) => {
        setOrganizations((orgs) => {
          const updatedOrganizations = orgs.filter((org) => org.id !== organizationId);

          return updatedOrganizations;
        });
      };

      const handleNodeClick = async (e) => {
        setOrganizations((orgs) => {
          const updatedOrganizations = orgs.map((org) => {
            if (org.id === e.itemData.id) {
              return { ...org, selected: true };
            }

            if (org.selected) {
              return { ...org, selected: false };
            }

            return org;
          });

          return updatedOrganizations;
        });
        api.setData("selectedOrganization", e.itemData.id);
      };

      React.useEffect(() => {
        if (api.getData("currentTenant") != null) {
          refreshData();
        }

        api.on("refresh", (data) => {
          if (data.name === "organization") {
            if (data.value && data.action === "update") {
              refreshSingleData(data.value);
            } else if (data.value && data.action === "add") {
              addSingleData(data.value);
            } else if (data.value && data.action === "delete") {
              removeSingleData(data.value);
            } else {
              refreshData();
            }
          }
        });

        api.on("store-data", ({ name, value }) => {
          if (name === "currentSite") {
            console.log("DataChanged name:" + name + " value:" + value);
            refreshData();
            api.setData("selectedOrganization", "");
          }
        });
      }, []);

      return (
        <>
          <div
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              borderRadius: "0.25rem",
            }}
          >
            <div style={{ margin: "1rem 1rem 1rem 1rem", fontSize: 24, opacity: 0.8 }}>Organizations</div>
            <div
              style={{
                display: "flex",
                flexGrow: 2,
                margin: " 0 1rem 1rem 1rem",
                backgroundColor: "#FFF",
              }}
            >
              <div className="list-items-container">
                {isLoading ? (
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <LoadIndicator id="small-indicator" height={60} width={60} />
                  </div>
                ) : (
                  <TreeView
                    dataSource={organizations}
                    ref={(ref) => {
                      this.treeView = ref;
                    }}
                    dataStructure="plain"
                    keyExpr="id"
                    parentIdExpr="parentId"
                    selectionMode="multiple"
                    displayExpr="name"
                    selectNodesRecursive={false}
                    searchEnabled={true}
                    selectedExpr="selected"
                    showCheckBoxesMode="none"
                    searchMode="contains"
                    onItemClick={handleNodeClick}
                  >
                    <SearchEditorOptions stylingMode="outlined"></SearchEditorOptions>
                  </TreeView>
                )}
              </div>
              <div className="extension-list">
                <api.Extension name="extension-list" params={{ extensions: "organization" }}></api.Extension>
              </div>
            </div>
          </div>
        </>
      );
    });
  },
};
