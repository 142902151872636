import * as React from "react";
import { Pilet, PiletApi } from "piral-core";
import { useState } from "react";
import { mutation, query } from "../../Services/GraphQL";
import {
  gqlAddOrganization,
  gqlDeleteOrganization,
  gqlLocations,
  gqlOrganization,
  gqlQueryOrganizationDependencies,
  gqlUpdateOrganization,
} from "./OrganizationQuery";
import TextBox from "devextreme-react/text-box";
import InfoIcon from "../../assets/icons/ic_info_24px.svg";
import Validator, { RequiredRule, StringLengthRule } from "devextreme-react/validator";
import TagBox from "devextreme-react/tag-box";
import { gqlSites } from "../Sites/SiteQuery";
import { IOrganization } from "./IOrganization";
import { deleteEntityGeneric } from "../ConfirmDeleteModal/ConfirmDeleteModalPilet";
import { ISite } from "../Sites/ISite";
import TreeView from "devextreme-react/tree-view";
import { ILocation } from "../Locations/ILocation";
import { ILocationTreeNode, ISelectableLocation, updateNodeRecursively } from "../Devices/ILocation";
import EditButtonRowWithParentChild from "../../Components/EditButtonRow/EditButtonRowWithParentChild";
import { Button } from "devextreme-react";
import { buildTree } from "../../util/treeUtilities";

export const OrganizationPropertiesPilet: Pilet = {
  name: "Organization Properties Module",
  version: "1.0.0",
  spec: "v2",
  dependencies: {},
  config: {},
  basePath: "/pilets",
  link: "/pilets/connector",
  setup(api: PiletApi) {
    api.registerExtension(
      "organization-properties",
      ({ params }) => {
        const emptyOrganization: IOrganization = {
          id: "",
          parentId: null,
          name: "",
          description: "",
          sites: [],
          dependencies: [],
          locations: [],
        };

        const [organization, setOrganization] = useState<IOrganization>(emptyOrganization);
        const [organizationSites, setOrganizationSites] = useState<string[]>([]); // The site IDs enabled for this organization.
        const [sites, setSites] = useState<ISite[]>([]);
        const [editMode, setEditMode] = useState<boolean>(false);
        const [locationTree, setLocationTree] = useState<ILocationTreeNode[]>([]);
        const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);

        const formPaddingStyle: React.CSSProperties = { paddingBottom: "1rem" };

        const areaWrapperPaddingDynamicHeightStyle: React.CSSProperties = {
          border: "1px solid rgb(0,0,0,0.52)",
          padding: "6px 10px 0 15px",
          borderRadius: "4px",
          margin: "0 15px 10px 15px",
        };

        const labelStyle: React.CSSProperties = {
          fontSize: "12px",
          position: "relative",
          top: "-16px",
          zIndex: "2",
          paddingLeft: "2px",
          paddingRight: "2px",
          backgroundColor: "white",
          color: "rgba(148,148,148,.87)",
        };

        React.useEffect(() => {
          api.on("store-data", async ({ name, value }) => {
            if (name === "selectedOrganization" && value != "") {
              await refreshData(value);
              console.log(`New value is "${value}"!`);
            }

            if (name === "currentTenant") {
              refreshSiteData();
              loadLocationsGeneral();
            }
          });

          if (api.getData("currentTenant")) {
            refreshSiteData();
            loadLocationsGeneral();
          }
        }, []);

        const loadLocationsGeneral = () => {
          const siteIds = organization.sites.map((x) => x.id);
          const selectedLocationIds = organization.locations.map((x) => x.id);
          loadLocations(siteIds, selectedLocationIds);
        };

        React.useEffect(() => {
          const selectedLocationIds = organization.locations.map((l) => l.id);
          loadLocations(organizationSites, selectedLocationIds);
        }, [organizationSites]);

        const refreshSiteData = async () => {
          const result = await query<ISite[]>(gqlSites);
          setSites(result);
        };

        const loadLocations = async (siteIds: string[], selectedLocationIds: string[]) => {
          const result = await query<ISelectableLocation[]>(gqlLocations);
          var filtered = siteIds ? result.filter((x) => siteIds.includes(x.site.id)) : result;
          var locationTree = buildTree<ILocationTreeNode>(
            filtered,
            (x) => x.id,
            (x) => x.parentId,
            (x) => x.children,
            (x, xs) => {
              x.children = xs;
            }
          );
          locationTree = locationTree.map((node) => updateNodeRecursively(selectedLocationIds, node));
          setLocationTree(locationTree);
          console.debug("Loaded locations, sites:", siteIds, "tree: ", locationTree);
        };

        /**
         * Updates the locations tree by marking the appropriate nodes as selected or not selected.
         * Also sets the proper value for isExpanded. For the read-only view, we need to expand the right nodes
         * so that all selected nodes are visible.
         * @param selectedLocationIds List of selected location IDs which will be shown as marked.
         */
        const refreshLocationTree = async (selectedLocationIds: string[]) => {
          setLocationTree((prevLocations) =>
            prevLocations.map((loc) => updateNodeRecursively(selectedLocationIds, loc))
          );
        };

        const refreshData = async (organizationId) => {
          setEditMode(false);
          setDeleteInProgress(false);

          const result = await query(gqlOrganization, { organizationId: [organizationId] });

          if (result) {
            setOrganization(result[0]);
            const siteIds: string[] = result[0].sites.map((site) => site.id);
            const selectedLocationIds = result[0].locations.map((l) => l.id);
            setOrganizationSites(siteIds);
            await loadLocations(siteIds, selectedLocationIds);
          }
        };

        const updateEntity = async (e) => {
          if (!e.validationGroup.validate().isValid) return;

          try {
            const organizationToSave = {
              parentId: organization.parentId,
              name: organization.name,
              description: organization.description,
              locations: organization.locations.map((l) => l.id as string),
            };

            organizationToSave["siteIds"] = organizationSites;

            if (organization.id === "*") {
              const result = await mutation<IOrganization>(gqlAddOrganization, { input: organizationToSave });

              if (result) {
                setOrganization(result);
                api.emit("refresh", { name: "organization", value: result.id, action: "add" });
                setEditMode(false);
              } else {
                api.showNotification("Error adding organization", { type: "error" });
              }

              return;
            }

            const result = await mutation<IOrganization>(gqlUpdateOrganization, {
              id: organization.id,
              input: organizationToSave,
            });

            if (result) {
              setOrganization(result);
              api.emit("refresh", { name: "organization", value: result.id, action: "update" });
              setEditMode(!editMode);
            } else {
              api.showNotification("Error updating organization", { type: "error" });
            }
          } catch (error) {
            console.error("Error refreshing data:", error);
          }
        };

        const handleValueChanged = async (e, field) => {
          if (e.event) {
            var newOrganization = { ...organization };
            newOrganization[field] = e.value;
            setOrganization(newOrganization);
          }
        };

        const handleSiteValueChanged = async (e) => {
          setOrganizationSites(e);
        };

        const deleteOrganization = async () => {
          setDeleteInProgress(true);
          const deleted = await deleteEntityGeneric<IOrganization>(
            api,
            organization.id,
            "organization",
            gqlQueryOrganizationDependencies,
            gqlDeleteOrganization
          );
          if (deleted) {
            setOrganization(emptyOrganization);
          }
          setDeleteInProgress(false);
        };

        const onAddClick = async (e) => {
          const parent = e.itemData == "Add root" ? null : organization.id;

          setEditMode(true);
          setOrganizationSites([]);
          setOrganization({ ...emptyOrganization, id: "*", parentId: parent });
          refreshLocationTree([]);
        };

        if (organization.id === "")
          return (
            <div style={{ display: "flex" }}>
              <Button
                width={120}
                text="Outlined"
                type="normal"
                stylingMode="outlined"
                onClick={() => onAddClick({ itemData: "Add root" })}
              >
                Add root
              </Button>
              <div style={{ flexGrow: 1 }}></div>
            </div>
          );

        return (
          <div>
            <form>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}></div>
                <EditButtonRowWithParentChild
                  deleteInProgress={deleteInProgress}
                  editMode={editMode}
                  onCancel={() => {
                    if (organization.id == "*") {
                      setOrganization({ ...emptyOrganization });
                    } else {
                      refreshData(organization.id);
                    }

                    setEditMode(false);
                  }}
                  onEdit={() => setEditMode(true)}
                  onDelete={() => deleteOrganization()}
                  onSave={updateEntity}
                  onAdd={onAddClick}
                />
              </div>
              <div style={{ padding: "0 1rem 1rem 1rem" }}>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Name"
                    onValueChanged={(e) => handleValueChanged(e, "name")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={organization.name}
                  >
                    <Validator>
                      <RequiredRule message="Name is required" />
                      <StringLengthRule max={40} message="Max 40 characters" />
                    </Validator>
                  </TextBox>
                </div>
                <div style={formPaddingStyle}>
                  <TextBox
                    readOnly={!editMode}
                    label="Description"
                    onValueChanged={(e) => handleValueChanged(e, "description")}
                    labelMode="floating"
                    stylingMode="outlined"
                    value={organization.description}
                  >
                    <Validator>
                      <StringLengthRule max={5000} message="Max 5000 characters" />
                    </Validator>
                  </TextBox>
                </div>
                <div style={formPaddingStyle}>
                  <TagBox
                    readOnly={!editMode}
                    label="Sites"
                    labelMode="floating"
                    stylingMode="outlined"
                    items={sites}
                    displayExpr="name"
                    valueExpr="id"
                    value={organizationSites}
                    placeholder="Choose Site..."
                    onValueChange={(e) => handleSiteValueChanged(e)}
                  >
                    <Validator>
                      <RequiredRule message="Site is required" />
                    </Validator>
                  </TagBox>
                </div>
              </div>

              <div style={areaWrapperPaddingDynamicHeightStyle}>
                <span style={labelStyle}>Locations</span>
                <div style={formPaddingStyle}>
                  <TreeView
                    disabled={!editMode}
                    items={locationTree}
                    dataStructure="tree"
                    selectedItemKeys={organization.locations.map((l) => l.id)}
                    keyExpr="id"
                    selectedExpr="selected"
                    expandedExpr="isExpanded"
                    selectionMode="multiple"
                    itemsExpr="children"
                    displayExpr="name"
                    searchEnabled={editMode}
                    searchMode="contains"
                    selectNodesRecursive={false}
                    showCheckBoxesMode="normal"
                    onItemSelectionChanged={(e) => {
                      setOrganization({
                        ...organization,
                        locations: e.component.getSelectedNodeKeys().map((key) => {
                          return { id: key, name: "" } as ILocation;
                        }),
                      });
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        );
      },
      { type: "organization", sortOrder: 1, name: "Organization properties", icon: InfoIcon }
    );
  },
};
